.hac-hacd-swap {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

/* 大屏幕设备 */
@media (min-width: 1200px) {
  .hac-hacd-swap {
    width: 80%;
  }
}

/* 中等屏幕设备 */
@media (max-width: 1199px) and (min-width: 769px) {
  .hac-hacd-swap {
    width: 80%;
  }
}

/* 小屏幕设备 */
@media (max-width: 768px) {
  .hac-hacd-swap {
    width: 90%;
    padding: 10px;
  }
}

/* 超小屏幕设备 */
@media (max-width: 480px) {
  .hac-hacd-swap {
    width: 95%;
    padding: 5px;
  }
}

h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  text-align: center; /* 添加这行 */
}

p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: center; /* 添加这行 */
}

form {
  display: flex;
  flex-direction: column;
  align-items: center; /* 添加这行 */
}

input, textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  max-width: 500px;
  text-align: left;
  box-sizing: border-box;
  color: #333; /* 设置文字颜色 */
  font-weight: normal; /* 设置文字粗细 */
  font-family: inherit; /* 确保字体一致 */
}

textarea.hacd-input {
  height: 100px;
  resize: none;
}

input::placeholder, textarea::placeholder {
  color: #999;
  text-align: left;
  font-style: normal;
  font-weight: normal; /* 确保占位符文字粗细一致 */
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%; /* 添加这行 */
  max-width: 400px; /* 添加这行 */
}

button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .hac-hacd-swap {
    padding: 10px;
    width: 100%; /* 添加这行 */
  }

  h1 {
    font-size: 20px;
  }

  p {
    font-size: 12px;
  }

  input, button {
    font-size: 14px;
    width: 100%; /* 添加这行 */
  }
}

.result-container {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  text-align: left;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.button-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.button-row.single {
  justify-content: center;
}

.button-row button,
.button-row .button-link {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.button-row .button-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .button-row {
    flex-direction: column;
  }
}

pre {
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin-bottom: 5px;
}

.transaction-info {
  margin-bottom: 15px;
}

.transaction-info p {
  margin: 5px 0;
  word-break: break-all;
  text-align: left;
}

.transaction-info .label {
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
}

.transaction-info .value {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 3px;
  font-family: monospace;
  font-size: 12px;
  word-break: break-all;
}

@media (max-width: 768px) {
  .transaction-info .value {
    font-size: 10px;
  }

  /*.txbody {*/
  /*  font-size: 10px;*/
  /*}*/
}

.hac-hacd-swap form {
  display: flex;
  flex-direction: column;
}

.hac-hacd-swap input,
.hac-hacd-swap button {
  margin-bottom: 10px;
  padding: 10px;
}

.result-container {
  margin-top: 20px;
  text-align: left;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.transaction-info {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
}

/*.txbody {*/
/*  background-color: #f0f0f0;*/
/*  padding: 10px;*/
/*  border-radius: 4px;*/
/*  overflow-x: auto;*/
/*}*/

@media (max-width: 600px) {
  .button-group {
    flex-direction: column;
  }

  .button-group button,
  .button-group a {
    margin-bottom: 10px;
  }
}

.instructions {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: left;
  max-width: 400px; /* 添加这行 */
  margin-left: auto; /* 添加这行 */
  margin-right: auto; /* 添加这行 */
}

 .warning {
  color: #d9534f;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  text-align: center; /* 添加这行 */
}

.instructions h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.instructions ol {
  padding-left: 20px;
}

.instructions li {
  margin-bottom: 5px;
}

.instructions .note {
  font-style: italic;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .instructions {
    padding: 15px;
  }

  .instructions h2 {
    font-size: 16px;
  }

  .instructions li, .instructions .note {
    font-size: 14px;
  }
}

.hacd-input {
  height: 90px;
  resize: vertical;
  overflow-y: auto;
}

/* 确保其他输入框样式保持不变 */
input, textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  max-width: 500px; /* 增加宽度 */
  text-align: left;
  box-sizing: border-box; /* 确保padding不会增加总宽度 */
}

textarea.hacd-input {
  height: 100px; /* 固定高度 */
  resize: none; /* 禁止用户调整大小 */
}

input::placeholder, textarea::placeholder {

  /* color: #999; */
  text-align: left;
  font-style: normal; /* 将斜体改为正常字体 */
}

/* ... 其他样式保持不变 ... */
