.sign-transaction {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-transaction h1 {
  text-align: center;
  margin-bottom: 20px;
}

.sign-transaction textarea {
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
}

.sign-transaction button {
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
}

.sign-transaction button:hover {
  background-color: #3a7bc8;
}

.signed-result {
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.signed-result h2, .signed-result h3 {
  margin-bottom: 10px;
}

.signed-result p {
  margin-bottom: 10px;
  word-break: break-all;
}

.signed-result .label {
  font-weight: bold;
  margin-right: 5px;
}

.signed-result .value {
  font-family: monospace;
}

.transaction-details {
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.signatures {
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.signatures h3 {
  margin-bottom: 10px;
}

.signatures p {
  margin-bottom: 5px;
}

.signatures .signed {
  color: green;
}

.signatures .unsigned {
  color: red;
}
