.inscription-operations {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.operation-type-selector {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.operation-type-selector button {
  padding: 10px 20px;
  border: none;
  background: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  transition: background-color 0.3s;
}

.operation-type-selector button:hover {
  background: #0056b3;
}

.operation-type-selector button.active {
  background: #28a745;
}

.inscription-operations form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.inscription-operations input,
.inscription-operations textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.inscription-operations textarea.diamonds-input {
  height: 100px;
  resize: vertical;
}

.inscription-operations textarea.content-input {
  height: 150px;
  resize: vertical;
}

.inscription-operations button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
}

.inscription-operations button[type="submit"]:hover {
  background-color: #0056b3;
}

.result-container {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.button-row {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.button-row.single {
  justify-content: center;
}

.button-link {
  display: inline-block;
  padding: 12px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
  width: 100%;
}

.button-link:hover {
  background-color: #218838;
}

.transaction-info {
  margin-top: 20px;
}

.transaction-info p {
  margin: 10px 0;
  word-break: break-all;
}

.label {
  color: #666;
  font-weight: bold;
}

.value {
  font-family: monospace;
} 